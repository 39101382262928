@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Inter:wght@100..900&display=swap');


/* font-family: "Inter", sans-serif;
font-family: "Bad Script", cursive; */
.h100 {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.h100 .liness {
    font-size: 34px !important;
}

.cardstyle{
    background: var(--cardbg);
    border: 1px solid var(--bordergrey);
    border-radius: 20px;
    padding: 25px;
}

.landing {
    /* background-color: #202722; */
    background: url("../images/bg.jpg") no-repeat !important;
    height: 100%;
    width: 100%;
    background-size: 100% 100% !important;
    overflow: hidden;
}

.landingpage {
    position: relative;
    z-index: 6;
}

.lightimg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.heading {
    color: var(--whitetext);
    font-weight: 800;
    font-size: 60px;
}

.subtext {
    font-family: "Bad Script", cursive !important;
    color: #C9FF69;
    font-weight: 400 !important;
}

.liness {
    color: var(--whitetext);
    font-size: 15px;
}

.landbtn {
    background-color: #96AB6D !important;
    border: 1px solid #96AB6D !important;
    color: var(--blacktext) !important;
    border-radius: 20px !important;
    padding: 6px 22px !important;
    font-weight: 500 !important;
}

.landborderbtn {
    background-color: transparent !important;
    border: 1px solid #ffff !important;
    color: var(--whitetext) !important;
    border-radius: 20px !important;
    padding: 6px 22px !important;
    font-weight: 500 !important;
}

.landbtn:hover,
.landborderbtn:hover {
    background-color: #C9FF69 !important;
    border: 1px solid #C9FF69 !important;
    color: var(--blacktext) !important;
}

.firsth1 {
    color: var(--whitetext);
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    word-wrap: break-word;
}

.mainheading {
    color: #C9FF69;
}

.step {
    color: var(--whitetext);
    font-weight: 600;
}

.position {
    position: relative;
    z-index: 6;
}

.oneimg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 40%;
}

.backimg {
    background: url("../images/grayimg.png") no-repeat;
    border-radius: 20px;
    border: 1px solid #96ab6d52;
}

.backlastimg {
    background: url("../images/backlast.png") no-repeat;
    background-position: center;
    border: 1px solid #96ab6d6e;
    border-radius: 20px;
    /* height: 400px; */
}

.landtabs .navall a {
    background-color: #202722 !important;
    background: #161616 !important;
    border-radius: 20px !important;
    color: var(--whitetext) !important;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid #ffffffa3 !important;
    font-weight: 500 !important;
}

.landtabs .navall a[class="nav-link active"] {
    background: #fff !important;
    color: var(--blacktext) !important;
}

.leftarrow {
    position: absolute;
    width: 21px;
    height: 21px !important;
    right: 14px;
    top: 28%;
}
.leftarrowss{
    width: 18px;
    height: 18px;
}

.accordionland [class="accordion-body"],
.accordionland [class="accordion-button"] {
    color: #fff !important;
    font-weight: 300;
}

.accordionland [class="accordion-header"] button {
    color: #fff !important;
}

.accordionland [class="accordion-body"] {
    background: #232a25;
    color: #adadad !important;
}

.accordionland [class="accordion-item"] {
    border-color: transparent !important;
    margin-bottom: 8px;
}

.accordionland [class="accordion-header"] button:focus {
    border-color: transparent !important;
    box-shadow: unset !important;
}

/* .accordionland [class="accordion-button"]:focus{
    border-color: transparent !important;
    box-shadow: unset !important;
  } */
.accordionland [class="accordion-header"] {
    background: #2f3e3457;
    /* background-image: linear-gradient(#2f3e34, #2a3d29); */
    border-radius: 2px;
}

.accordionland [class="accordion-header"] button::after {
    background-image: url("../images/leftss1.svg") !important;
}

.accordionland [class="accordion-header"] button:not(.collapsed) {
    background-color: transparent !important;
    box-shadow: unset !important;
}




.accordion-wrapper {
    display: block;
    height: 350px;
    max-height: 100%;
    width: 750px;
}

#accordion {
    max-width: 36.125rem;
    margin-left: 15px;
}





.mainroad [class="accordion-header"] button{
    font-size: 22px;
}
.mainroad [class="accordion"] {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(-98%) rotate(-90deg);
    -webkit-transform-origin: right top;
    -moz-transform: translateX(-98%) rotate(-90deg);
    -moz-transform-origin: right top;
    -o-transform: translateX(-98%) rotate(-90deg);
    -o-transform-origin: right top;
    transform: translateX(-98%) rotate(-90deg);
    transform-origin: right top;
    margin-bottom: 15px;
    position: relative;
}
.mainroad .fw600{
    font-weight: 600;
}
.mainroad [class="accordion-item"] {
    border: none;
}
.mainroad [class="accordion-body"]{
    color: var(--whitetext);
    padding: 1.3rem 2rem 1rem 1.5rem !important;
}
.mainroad [class="accordion"] button {
    background: none;
    box-shadow: none;
    color: var(--whitetext);
    font-weight: 300;
}
.mainroad [class="accordion"] button::after {
    display: none;
}
.mainroad [class="accordion-item"] {
    position: relative;
  
}
.mainroad [class="accordion-header"] {
    min-height: 105px;
    max-height: 105px;
    display: flex;
    align-items: center;
}

.mainroad [class="accordion-item"]:nth-child(odd)::before {
    content: "";
    background: url('../images/right.svg');
    height: 350px;
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    transform: rotate(90deg);
    top: -170px;
    z-index: -1;
}

.mainroad [class="accordion-item"]:last-child::after {
    content: "";
    background: url('../images/left.svg');
    height: 350px;
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    transform: rotate(90deg);
    top: -65px;
    z-index: -1;
}
.mainroad [class="accordion-item"]:last-child [class="accordion-body"]::before {
  display: none;
}
.mainroad  [class="accordion-body"]{
    transform: rotate(90deg);
}
.mainroad [class="accordion-item"]:nth-child(even)::before {
    content: "";
    background: url('../images/left.svg');
    height: 350px;
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    transform: rotate(90deg);
    top: -170px;
    z-index: -1;
}
.mainroad [class="accordion-item"]:nth-child(odd) [class="accordion-body"]::before{
    position: absolute;
    content: "";
    /* width: 93%;
    height: 1px;
    background: var(--roadline);
    right: 60px;
    top: -13px; */

    width: 100%;
    height: 1px;
    background: var(--roadline);
    right: 50px;
    top: -5px;
}
.mainroad [class="accordion-item"]:nth-child(even) [class="accordion-body"]::before{
    position: absolute;
    content: "";
    /* width: 93%;
    height: 1px;
    background: var(--roadline);
    right: 60px;
    bottom: -13px; */
    width: 100%;
    height: 1px;
    background: var(--roadline);
    right: 50px;
    bottom: -5px;
}
.mainroad [class="accordion-item"]:nth-child(5) [class="accordion-body"]{
    padding: 8% !important;

}
/* .mainroad [class="accordion-item"]:nth-child(even)::after {
    content: "";
    background: url('../images/left.svg');
    height: 350px;
    width: 100%;
    background-size: 100% 100%;
    position: absolute;
    transform: rotate(90deg);
    top: -63%;
    z-index: -1;
} */

.mainroad .roadwrapper {
    display: block;
    height: 350px;
    max-height: 100%;
    width: 350px;
}




@media screen and (max-width:767px) {
    .oneimg {
        width: 17%;
    }
}

@media screen and (max-width:575px) {
    .heading {
        font-size: 40px;
    }
    .mainroad [class="accordion-item"]:nth-child(odd)::before, .mainroad [class="accordion-item"]:nth-child(even)::before, .mainroad [class="accordion-item"]:last-child::after { 
        display: none;
    }
    .mainroad [class="accordion-header"] {
        min-height: 85px;
        max-height: 85px;
    }
    .oneimg {
        width: 22%;
        left: 20px;
    }

    .leftarrow {
        width: 14px !important;
        height: 24px !important;
    }

    .liness {
        font-size: 14px !important;
    }

    .accordion-button::after {
        width: 1rem !important;
        height: 1rem !important;
    }
}







.mainroadss [class="accordion-body"]{
   color: var(--whitetext);
}
.fw300{
    font-weight: 200 !important;
}
.mainroadss [class="accordion-body"]{
    transform: rotate(90deg);
    padding-top: 8%;
    padding-bottom: 9%;
}
 .mainroadss div[class="accordion-header"] < .mainroadss div[class="accordion-item"] {
    background-color: red;
}
.mainroadss [class="accordion-header"] button{
    font-size: 25px;
}
.mainroadss [class="accordion-header"] button::after{
    background-image: unset !important;
}
.mainroadss [class="collapsed"]::after{
    background-image: unset !important;
}
.mainroadss [class="accordion-item"]:nth-child(odd){
    border: 1px solid var(--roadline);
    border-radius: 0px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
    border-width: 80% !important;
   
}
/* .mainroadss [class="accordion-item"]:nth-child(1)::before{
    content: "";
    background-color: #232a25;
    width: 35px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
} */
.mainroadss [class="accordion-item"]:nth-child(3)::before{
    content: "";
    background-color: #232a25;
    width: 35px;
    height: 1px;
    position: absolute;
    right: 0;
    top: -1px;
    left: 0;
    z-index: 0;
}
.mainroadss [class="accordion-item"]:nth-child(5)::before{
    content: "";
    background-color: #232a25;
    width: 35px;
    height: 1px;
    position: absolute;
    right: 0;
    top: -1px;
    left: 0;
    z-index: 0;
}
/* .mainroadss [class="accordion-item"]:nth-child(5)::before{
    content: "";
    background-color: #232a25;
    width: 35px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
} */
.mainroadss [class="accordion-item"]:nth-child(1)::after{
    content: "";
    background-color: #232a25;
    width: 33px;
    height: 1px;
    position: absolute;
    left: 0;
}
.mainroadss [class="accordion-item"]:nth-child(2)::after{
    content: "";
    background-color: #232a25;
    width: 33px;
    height: 1px;
    position: absolute;
    left: 0;
}
.mainroadss [class="accordion-item"]:nth-child(3)::after{
    content: "";
    background-color: #232a25;
    width: 33px;
    height: 1px;
    position: absolute;
    left: 0;
}
.mainroadss [class="accordion-item"]:nth-child(4)::after{
    content: "";
    background-color: #232a25;
    width: 33px;
    height: 1px;
    position: absolute;
    left: 0;
}
.mainroadss [class="accordion-item"]:nth-child(even){
    border: 1px solid var(--roadline);
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-width: 80% !important;
    
   
   
}
.mainroadss [class="accordion-header"] button{
    background: none;
    box-shadow: none;
    color: var(--whitetext);
}
.mainroadss .roadwrapper {
    display: block;
    height: 350px;
    max-height: 100%;
    width: 350px;
    /* margin: auto; */
}
.mainroadss [class="accordion"] {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(-98%) rotate(-90deg);
    -webkit-transform-origin: right top;
    -moz-transform: translateX(-98%) rotate(-90deg);
    -moz-transform-origin: right top;
    -o-transform: translateX(-98%) rotate(-90deg);
    -o-transform-origin: right top;
    transform: translateX(-98%) rotate(-90deg);
    transform-origin: right top;
    margin-bottom: 15px;
    position: relative;

}
.mainroadss [class="accordion-item"] {
    position: relative;
}
/* .mainroadss [class="accordion-item"]:nth-child(5)::before {
    content: "";
    background:url("../images/e3dge\ right.svg") no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
} */
/* .mainroadss [class="accordion-item"]:nth-child(1)::before {
    content: "";
    background:url("../images/e3dge\ right.svg") no-repeat;
    position: absolute;
    right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        left: -35px;
        top: -66px;
} */



@media screen and (max-width:1199px) {
    .mainroadss [class="accordion"] {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateX(-98%) rotate(-90deg);
        -webkit-transform-origin: right top;
        -moz-transform: translateX(-98%) rotate(-90deg);
        -moz-transform-origin: right top;
        -o-transform: translateX(-98%) rotate(-90deg);
        -o-transform-origin: right top;
        transform: unset;
        transform-origin: right top;
        margin-bottom: 15px;   
    }
    .mainroadss .roadwrapper {
        display: block;
        height: unset;
        max-height: unset;
        width: 450px;
        margin: auto;
    }
    .mainroadss [class="accordion-body"]{
        transform:unset;
    }
}


@media screen and (max-width:991px) {
    .mainroad [class="accordion"] {
        transform: unset;
    }
    .mainroad .roadwrapper {
        height: unset;
        max-height: 100%;
        width: 350px;
    }
    .mainroad  [class="accordion-body"]{
        transform:unset;
    }
    .mainroad [class="accordion-item"]:nth-child(odd) [class="accordion-body"]::before{
        position: absolute;
        content: "";
        width: 93%;
        height: 1px;
        background: var(--roadline);
        right: -163px;
        top: 218px;
        transform: rotate(90deg) !important;
    }
    .mainroad [class="accordion-item"]:nth-child(even) [class="accordion-body"]::before{
        position: absolute;
        content: "";
        width: 93%;
        height: 1px;
        background: var(--roadline);
        left: -163px;
        top: 218px;
        transform: rotate(90deg) !important;   
    }

    .mainroadss .roadwrapper {
        display: block;
        height: unset;
        max-height: unset;
        width: 450px;
    }
}



@media screen and (max-width:767px) {
    .mainroadss .roadwrapper {
        display: block;
        height: unset;
        max-height: unset;
        width: 450px;
    }
}



@media screen and (max-width:575px) {
  .landing .container{
        max-width: 90% !important;
    }
    .mainroad .roadwrapper {
        width: 100%;
    } 
    /* .mainroad  [class="accordion-body"]{
        border-left: 1px dashed #8ead73;
    } */
    .mainroad [class="accordion"] {
        border-left: 2px dashed #8ead73;
    }
    .mainroadss .roadwrapper {
        display: block;
        height: unset;
        max-height: unset;
        /* width: 300px; */
    }
    .mainroad [class="accordion-item"]:nth-child(odd) [class="accordion-body"]::before, .mainroad [class="accordion-item"]:nth-child(even) [class="accordion-body"]::before{
        display: none;
    }
    .mainroadss [class="accordion-body"]{
        padding-top: 3%;
        padding-bottom: 6%;
    }
    .mainroad .roadwrapper {
        margin: auto;
    }
}


@media screen and (max-width:374px) {
    .landbtn, .landborderbtn {
        padding: 6px 17px !important;
        font-size: 15px !important;
    }
    .mainroad .roadwrapper {
        width: 290px;
        margin: auto;
    }
    .mainroad [class="accordion-item"]:nth-child(odd)::before {
        height: 290px;
        top: -130px;
    }
    .mainroad [class="accordion-item"]:nth-child(even)::before {
        height: 290px;
        top: -150px;
    }
    .mainroad [class="accordion-body"]{
        color: var(--whitetext);
        padding: 0.2rem 2rem 1rem 1.5rem !important;
    }
    .mainroad [class="accordion-item"]:last-child::after {
        height: 290px;
        top: -45px;
    }
    .mainroad [class="accordion-item"]:nth-child(odd) [class="accordion-body"]::before{
        content: "";
        width: 100%;
        left: 144px;
        top: 250px;
    }
    .mainroad [class="accordion-item"]:nth-child(even) [class="accordion-body"]::before{
        left: -135px;
        top: 240px;
    }
}
