/* settingallmodal */
.cardstyle{
    background: var(--cardbg);
    border: 1px solid var(--bordergrey);
    border-radius: 20px;
    padding: 25px;
}
.firsth1 {
    color: var(--whitetext);
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}
textarea{
    border-color: #727272 !important;
    background-color: #1726188c !important;
}
textarea:focus{
    box-shadow: unset !important;
    border-color: #727272 !important;
}
.qrimages{
    width: 150px;
    height: 150px !important;
    border: 1px solid #ffffffb3;
    padding: 4px;
}
.loaderzz {
    width: 40px;
    height: 40px;
    border: 5px solid #FFF;
    border-bottom-color: var(--themebtns);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
label {
    color: #fff;
    font-size: 18px;
}
.otpmodals input{
    width: 2em !important;
  }
  
  .numtablelist {
    height: 100vh;
    overflow-y: auto;
  }
  


.height100{
    height: 100vh;
}
.titlesss {
    color: var(--whitetext);
}

.porttext {
    font-weight: 300;
    color: var(--whitetext);
}

.permonth {
    color: var(--whitetext);
}


.custom_container {
    max-width: 95% !important;
}

.innerpages .innercontent {
    padding-top: 30px;
}

/* .innerpages .apipage .titlerow {
    text-align: center;
} */

/* .innerpages .apipage .titlerow {
    text-align: center;
} */

.innerpages .apipage .titlerow .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);
}

.maintitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);
}

.innerpages .cardstyle {
    background: var(--cardbg);
    border: 1px solid var(--bordergrey);
    border-radius: 20px;
    padding: 25px;
}

.innerpages .apipage .notfysec .cardstyle .head {
    font-size: 18px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
}

.innerpages .apipage .notfysec .cardstyle .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 10px;

}

.innerpages input {
    background: var(--inputclr);
    border: 1px;
    color: var(--whitetext);
    border: 1px solid var(--secclr);
    font-size: 14px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.innerpages input::placeholder {
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}

.innerpages input:focus {
    box-shadow: none;
    background: transparent;
    border: 1px solid var(--secclr);
}

.innerpages .apipage .formsec .labelname {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages .inputstyle [class="input-group-text"] {
    background: var(--thirdclr);
    height: 100%;
    border: none;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;

}

.innerpages .greenbtn {
    background: var(--thirdclr);
    border: 1px solid var(--thirdclr);
    color: var(--whitetext);
    /* padding: 10px; */
    width: 100%;
    font-size: 12px;
    height: 100%;
}

.innerpages .redbtn {
    background: var(--redclr);
    border: 1px solid var(--redclr);
    color: var(--whitetext);
    /* padding: 10px; */
    width: 100%;
    font-size: 12px;
    height: 100%;
}

.innerpages .greenbtn:hover {
    background: transparent;
    color: var(--whitetext);
    border: 1px solid var(--thirdclr);


}

.innerpages .redbtn:hover {
    background: transparent;
    color: var(--whitetext);
    border: 1px solid var(--redclr);


}

.innerpages .apipage .formsec .formcheck {
    display: flex;
    gap: 10px;
}

.innerpages .apipage .formsec .formcheck label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages .apipage .formsec .formcheck label a {
    color: var(--thirdclr);
    text-decoration: none;

}

.innerpages .apipage .formsec .btnsec {
    display: flex;
    align-items: center;
    gap: 10px;

}

.innerpages .apipage .formsec .btnsec button {
    width: 120px;
}

.innerpages .settingpage .titlerow .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);

}

.hrline {
    border-top: 1px solid #ffffff;
}


.h40{
    height: 40px;
}

/* history */
.dashborder {
    border: 1px solid #29653a7a;
    background-color: #162019;
    border-radius: 6px;
}

.whtclr {
    color: var(--whitetext);
}

.f14 {
    font-size: 14px;
}

.tablelist {
    border: 1px solid #515151 !important;
    border-radius: 20px !important;
    background-color: #162019 !important;
}

.accountcard .title {
    color: #fff;
}

.profileimg {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.timelinecircle {
    background-color: #25C852;
    border-radius: 50%;
    display: inline-block;
    height: 8px !important;
    width: 8px !important;
    animation: pulse-animation 2s infinite;
    position: absolute;
    right: 2px;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0 #25c8528c;
    }

    100% {
        box-shadow: 0 0 0 3px #25c8528c;
    }
}

.btnoutline {
    background-color: transparent !important;
    border: 1px solid #7EBE98 !important;
    border-radius: 24px !important;
    font-size: 13px !important;
    color: #7EBE98 !important;
}

.btnoutline:hover {
    background-color: #7EBE98 !important;
    color: #fff !important;
}

.btnredoutline {
    background-color: transparent !important;
    border: 1px solid #D17179 !important;
    border-radius: 24px !important;
    font-size: 13px !important;
    color: #D17179 !important;
}

.btnredoutline:hover {
    background-color: #D17179 !important;
    color: #fff !important;
}

.greencolor {
    color: #01B574 !important;
}


.tablelistss .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #29653A !important;
    border-radius: 8px 8px 0px 0px;
}

.tablelistss .nav-link {
    color: #fff !important;
}

@media screen and (min-width: 768px) and (max-width:991px) {
    .f14 {
        font-size: 13px;
    }
}

@media screen and (max-width: 349px) {
    .custom_container {
        max-width: 100% !important;
    }
}




/* login css */
.loginborder {
    /* background: linear-gradient(111.1deg, #232b23 5.12%,#232b23 10.12% ,#497d3f 105.13%); */
    background: url("../images/loginback.jpg") no-repeat;
    background-size: 100% 100% !important;
    height: 100%;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logincard {
    border: 1px solid #fdfefc;
    padding: 30px 50px;
    border-radius: 8px;
    background: url("../images/sullanback.png") no-repeat;
    background-position: center !important;
}

.loginsection .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: red !important;
    border-radius: 8px 8px 0px 0px;
}

.loginsection .nav-link {
    color: #fff !important;
}

.inputfiled {
    background-color: #1726188c !important;
    border-color: #727272 !important;
    color: #fff !important;
}

.inputfiled::placeholder {
    color: #ffffff96 !important;
}

.inputfiled:focus {
    box-shadow: unset !important;
}

.themebtn {
    background-color: var(--themebtns) !important;
    border: 1px solid var(--themebtns) !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
}

.themebtn:hover {
    background-color: transparent !important;
    border: 1px solid var(--themebtns) !important;
    color: var(--themebtns) !important;
}

.lightyclr {
    color: #C9FF69 !important;
    font-size: 14px !important;
}

.loginsection .navstyle a[class="nav-link active"] {
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    font-weight: 500 !important;
    padding: 8px 66px !important;
}

.loginsection .navstyle a {
    padding: 8px 66px !important;
}

@media screen and (max-width: 767px) {
    .loginborder {
        height: unset;
        padding: 30px 0px 30px 0px;
    }

}

@media screen and (max-width: 575px) {
    .loginborder {
        /* height: unset; */
        
        height: 100vh;
        padding: 30px 0px 30px 0px;
    }

    .logincard {
        padding: 30px 15px !important;
    }

    .loginsection .navstyle a[class="nav-link active"] {
        padding: 8px 30px !important;
    }

    .loginsection .navstyle a {
        padding: 8px 30px !important;
    }
}


.grclr{
    color: var(--themebtns);
}

/* header css */
.headerlogo {
    width: 150px;
}

.navlist .nava {
    color: #fff;
    text-decoration: none;
    padding: 0px 28px;
}

.navlist a:hover {
    color: #97D128;
}

.headerbtn {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: var(--blacktext) !important;
    font-weight: 500 !important;
}

.headerbtn:hover {
    background-color: #C9FF69 !important;
    border: 1px solid #C9FF69 !important;
}

.headerborderbtn {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: var(--whitetext) !important;
    font-weight: 500 !important;
}

.headerborderbtn:hover {
    background-color: #C9FF69 !important;
    border: 1px solid #C9FF69 !important;
    color: var(--blacktext) !important;
}

.footer h6 {
    color: var(--whitetext);
    font-size: 20px;
}

.footer ul {
    padding-left: 0px;
    list-style-type: none;
}

.footer li a,
.footer li {
    color: var(--whitetext);
    margin-top: 15px;
    text-decoration: none;
    font-weight: 300;
}

.footer li a:hover {
    color: #97D128;
}

.footerrow {
    border-bottom: 2px solid #374e3d47 !important;
    padding-bottom: 20px;
}

.copyright {
    color: #96AB6D;
    font-size: 14px;
}

.socialmedia a {
    margin-left: 10px;
    color: #96AB6D;
}

.socialmedia a:hover {
    filter: drop-shadow(2px 4px 12px #98a86b);
}


@media screen and (max-width:991px) {
    .mobilelogo {
        width: 130px;
    }

    .navlist a {
        margin-top: 20px;
    }

}