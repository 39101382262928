.alltables{
  height: 520px;
  overflow-y: auto;
}
.alltables::-webkit-scrollbar-track,.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.alltables::-webkit-scrollbar,.table-responsive::-webkit-scrollbar
{
	width: 10px;
  height: 8px;
	background-color: #243029;
}
.alltables::-webkit-scrollbar-thumb,.table-responsive::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #96AB6D;
}
.alltables tbody tr td{
  text-align: center !important;
  color: #fff;
}
.alltables thead th{
  text-align: center !important;
  color: #fff;
  padding: 18px 8px;
}
.alltables tbody tr{
  background: unset !important;
  padding: 18px 8px !important;
}
.alltables thead {
  border-bottom: 1px solid #515151;
  margin: 0px 5px;
}
.ggclr{
  /* background-color: #f9e5e21c !important; */
  background-color: #f9e5e200 !important;
  border-bottom: 2px solid #162019 !important;
}
.modal-content{
  background: var(--cardbg);
  border: 1px solid var(--bordergrey);
}
.modal-header{
  border-bottom: 1px solid transparent;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #96AB6D;
}
body .form-control:focus{
  color: #fff !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: #29653A !important;
  border-radius: 8px 8px 0px 0px;
}
.nav-link{
  color: #fff !important;
}
.btn-group button{
  border: 1px solid #383838 !important;
  background-color: transparent !important;
  font-size: 14px !important;
}
.btn-group button:hover{
  border: 1px solid #29653A !important;
  background-color: #29653A !important;
}

.MuiDataGrid-columnHeaderTitle{
  color: #fff;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.MuiDataGrid-cellContent{
  color: #ffff !important;
  font-size: 13px !important;
}
.MuiDataGrid-sortIcon{
  fill: 
  #fff !important;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-input,.MuiSvgIcon-fontSizeMedium,.MuiTablePagination-displayedRows,.MuiDataGrid-selectedRowCount{
  color: #fff !important;
}
.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus{
  outline: solid transparent 1px !important;
 }
 .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell--textLeft{
  justify-content: center !important;
}
 .MuiDataGrid-withBorderColor{
  border-color: transparent !important;
}
/* .MuiDataGrid-main{
  border: 1px solid #515151 !important;
  border-radius: 20px !important;
}
.MuiDataGrid-main{
  background-color: #162019 !important;
} */
/* #left-tabs-example-tabpane-opportunity{
  border-color: transparent !important;
} */
.MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor{
  border-color: red !important;
}
.MuiDataGrid-columnHeaders {
  border-color: #515151 !important; 
  margin-bottom: 12px !important;
  margin-left: 10px;
  margin-right: 10px;
}
.MuiTablePagination-toolbar{
  align-items: baseline !important;
}
.MuiDataGrid-main{
  overflow: auto;
}
.MuiDataGrid-row.Mui-selected{
 background-color: #29653a21 !important;
}
/* #left-tabs-example-tabpane-opportunity{
  border: 1px solid #515151 !important;
  border-radius: 20px !important;
  background-color: #162019 !important;
} */
.input-group-text{
  background-color: #29653A !important;
  border: #29653A !important;
}
.input-group-text svg{
 fill: #fff;
}
.MuiDataGrid-menuIcon, .MuiDataGrid-iconSeparator {
  display: none !important;
}
.rangeslider{
background: #303030 !important;
width: 100%;
}

.rangeslider-horizontal .rangeslider__fill{
  background-color: #3CDB6A !important;
}
.rangeslider-horizontal .rangeslider__handle{
  width: 20px !important;
  height: 20px !important;
}
.rangeslider-horizontal .rangeslider__handle:after{
  display: none !important;
}
.form-select{
 background-image: url("./assests/images/arrowsvg.svg") !important;
 background-color: #434343 !important;
 color: #fff !important;
 border-color: #434343 !important;
}
.form-select:focus{
  box-shadow: unset !important;
}
thead {
  text-align: center !important;
}
tr{
  border-color: transparent;
  /* background-color:  #F9E5E2 !important; */
}
tbody tr{
  background-color:  #f9e5e21c !important; 
  border-bottom: 2px solid #162019 !important;
}
/* .table-responsive .table, thead, tbody{
  background-color: transparent !important;
  background: transparent !important;
} */
.form-check label{
  color: #fff;
  font-size: 14px;
}
.react-tel-input input{
  background-color: #1726188c !important;
  border-color: #727272 !important;
  color: #fff !important;
  width: 100% !important;
}
.react-tel-input input:focus{
  box-shadow: unset !important;
  color: #fff !important;
}
.react-tel-input .flag-dropdown {
  background-color: #1726188c !important;
  border-color: #727272 !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .flag-dropdown.open .selected-flag{
  background-color: unset !important;
  background: unset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background: transparent !important;
}
.form-check-input{
  border: 1px solid #C9FF69 !important;
}
.form-check-input:checked{
  background-color: #c9ff6980 !important;;
}

.navbar-toggler{
  border: 1px solid #c6df94;
  background-color: #c6df94;
  /* border: 1px solid #C9FF69;
  background-color: #C9FF69; */
}

.offcanvas-end{
  background-color: #222922 !important;
}
.btn-close{
  filter: invert(1);
}
@media screen and (max-width:991px) {
  .offcanvas-end{
      width: 250px !important;
  }
}

/* .container{
  max-width: 90% !important;
} */
@media screen and (max-width:575px) {
  body{
   background-color: #263028 !important;
  }
  .container{
    max-width: 90% !important;
}
}


.no_data_center
{
  height: 100%;
  align-items: center;
  color:#fff;
  justify-content: center;
  display: flex;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
    