
.cardlist{
    border: 1px solid #515151;
    font-size: 12px;
}
.buycolor{
    color: #8BED0F;
}
.redcolor{
    color: #ED0F0F;
}
.cardlist{
    border-radius: 10px;
}
.cardflex{
    display: flex;
    border-bottom: 1px solid #515151;
    background-color: #162019;
    border-radius: 10px 10px 0px 0px;
}
.cardgreen{
    background-color: #29653A !important;
    border-radius: 0px 0px 10px 10px !important;
}
.droparrow{
    width: 13px;
    height: 13px;
    margin-left: 4px;
    object-fit: contain;
}
.whtclr{
    color: var(--whitetext);
}
.grayclr{
  color: #ababab;
}
.bncimg{
    width: 24px;
    height: 24px !important;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.whtbtn{
   background: #FFFFFF !important;
   border-radius: 20px !important;
   font-size: 13px !important;
}
.timelinecircle{
    background-color: #25C852;
    border-radius:50%;
    display: inline-block;
    height:13px !important;
    width: 13px !important;
    animation: pulse-animation 2s infinite;
   
}
@keyframes pulse-animation  {
    0%{
        box-shadow:0 0 0 0 #25c8528c;
    }
    100%{
        box-shadow: 0 0 0 5px #25c8528c;
    }
}

@media screen and (max-width: 360px) {
    .timelinecircle{
        height:7px !important;
        width: 7px !important;      
    }
    .whtbtn{
        font-size: 12px !important;
        padding: 0px 5px !important;
     }
   }

