@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --bgcolor: #202722;
  --themelinear:linear-gradient(81.33deg, #1F2421 7.09%, #546158 173.02%);
  --tablebg: #243529;
  --cardbg: #162019;
  --bordergrey:#515151;
  --whitetext:#fff;
  --blacktext:#000;
  --inputclr:#1D2B21;
  --secclr:#227a4378;
  --thirdclr:#29653A;
  --redclr:#E1514E;
  --settingcardbg: #2D2D2D;
  --settingbtnclr: #00CABA;
  --themebtns: #3CDB6A; 
  --bs-body-bg: transparent !important;
  --roadline: #C9FF69
}
.form-control:disabled {
  background-color: #e9ecef3d !important;
  opacity: 1;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bgcolor) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}



