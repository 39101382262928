.uploadimg{
  width: 140px;
  height: 140px;
  object-fit: contain;
  display: block;
  margin: auto;
    border: 1px solid #ffffff80;
}
.nextmob{
  color: #fff;
  font-size: 13px;
}
.mobs{
  color: var(--themebtns);
  cursor: pointer;
}

.noorder{
  color: #fff;
  display: flex;
  min-height: 360px;
  justify-content: center;
  padding-top: 145px;
}

.profimages{
  position: relative;
}
.profimages input{
  position: absolute;
  opacity: 0;
}
.profimages button{

}
.otpmodals input{
  width: 2em !important;
  height: 2em !important;
}
.otpmodals div {
  justify-content: center;
}

.settingscard {
    background: var(--settingcardbg);
    border: 2px solid var(--secclr);
    padding: 25px;
    border-radius: 20px;
        /* height: 168px;
        min-height: 168px; */
}
.pricing .settingscard{
  height: 350px;
  min-height: 350px;
}
.modalbody .cardfull .settingscard{
  height: 340px !important;
  min-height: 340px !important;
}
.modalbody .cardfull.cardss .settingscard{
  height: 350px !important;
  min-height: 350px !important;
}
/* .modalbody .cardfull .settingscard:hover{
  background-color: #162019;
} */
.settingscard .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext) !important;
}
.whtclr{
    color: var(--whitetext);
    font-weight: 500 !important;
}
.f12{
    font-size: 13px !important;
    
  }

  .themebtn{
    background-color: var(--themebtns) !important;
    border: 1px solid var(--themebtns) !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
  }
  .themebtn:hover{
    background-color:transparent !important;
    border: 1px solid var(--themebtns) !important;
    color: var(--themebtns) !important;
  }
  .grayclr{
     color: #ababab !important;
  }
  .width70{
    /* width: 70%; */
  }
  

  .redclr{
    color: #FF7676 !important;
    font-size: 14px !important;
    width: 95px;
    min-width: 95px;
  }
  .yelclr{
    color: #F7F5DA;
    font-size: 14px !important;
  }
  .grnclr{
    color: #1E9A1C;
    font-size: 14px !important;
    width: 95px;
    min-width: 95px;
  }

  @media screen and (max-width: 767px) {
  .allrowss{
    flex-wrap: nowrap !important;
  }
  }

  @media screen and (max-width: 575px) {
    .settingscard {
            height: unset;
            min-height: unset;
    }
    .modalbody .cardfull.cardss .settingscard{
      height: unset !important;
      min-height: unset !important;
    }
}

