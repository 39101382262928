.logoss{
    width: 95px;
    margin: auto;
    /* margin-right: 10px; */
}
.breaks{
    word-wrap: break-word;
    word-break: break-word;
}
.sidebarsec {
    position: relative;
}
.siderow{
    background: var(--themelinear);
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    width: 18%;
}
.siderow::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.siderow::-webkit-scrollbar{
	width: 9px;
	background-color: #F5F5F5;
}
.siderow::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #96AB6D;
}
@media screen and (min-width:1400px) {
    .siderow{
        width: 17%;
    }
}
@media screen and (max-width:1199px) {
    .siderow{
        width: 234px;
    }
}
/* @media screen and (max-width:991px) {
    .siderow{
        width: 234px;
    }
}
@media screen and (max-width:767px) {
    .siderow{
        width: 234px;
    }
} */

@media screen and (max-width:575px) {
    .siderow{
        width: 230px;
    }
}



.sidebarsec .siderow .logosec {
    padding: 40px;
    padding-top: 60px;
}
.sidebarsec .siderow .logosec .logoimg {
    width: 100%;
    height: 100%;

}
.sidebarsec .siderow .dropmenu {
    background: none;
    border: none;
    display: flex;
    align-items: start;
    gap: 20px;
    flex-direction: column;
}
.sidebarsec .siderow .iconrow a {
    text-decoration: none;
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.sidebarsec .siderow .ulstyle a {
    text-decoration: none;
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.sidebarsec .siderow .ulstyle a.activemenus{
    color: var(--themebtns);
}

.sidebarsec .siderow .menulist {
    padding-top: 40px;
}

.sidebarsec .siderow .menulist button {
    background: none !important;
    border: none;
    box-shadow: none;
}

.sidebarsec .siderow .menulist button[aria-expanded="true"] {
    background: none;
    box-shadow: none;

}

.sidebarsec .siderow .menulist button::after {
    filter: invert(100%);
    background-size: 15px;
}

.sidebarsec .siderow .menulist .normalacc button::after {
    display: none;
}

.sidebarsec .menulist [class="accordion"] {
    --bs-accordion-bg: none;
    --bs-accordion-border-color: none
}

.sidebarsec .menulist .iconrow {
    display: flex;
    gap: 10px;
    align-items: center;
}

.sidebarsec .menulist .iconrow img {
    width: 22px;
    height: 22px;
    object-fit: contain;

}

.sidebarsec .menulist .ulstyle {
    list-style: none;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.menubar {
    height: 50px;
    padding: 10px 20px;
}

.menubar svg {
    fill: var(--whitetext);
    font-size: 30px;
    position: absolute;

}

.closeicons svg {
    fill: var(--whitetext);
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;

}

.sidebarsec .menulist .activemenus button {
    background: var(--whitetext) !important;
    border-radius: 0px;
}

.sidebarsec .menulist .activemenus button .iconrow a {
    color: var(--blacktext);

}

.sidebarsec .menulist .activemenus button .iconrow img {
    filter: invert(1);
}

.sidebarsec .menulist .activemenus button::after {
    filter: invert(0);

}

.sidebarsec .siderow .menulist button:hover {
    background: var(--whitetext) !important;
    color: var(--blacktext) !important;
}
.sidebarsec .siderow .menulist button:hover p {
    color: var(--blacktext) !important;
}

.sidebarsec .siderow .menulist button:hover .iconrow a {
    color: var(--blacktext);

}
.sidebarsec .siderow .menulist button:hover .iconrow img {
    filter: invert(1);

}

.sidebarsec .siderow .menulist button:hover:after {
    filter: invert(0);

}
.sidebarsec .menulist  button .iconrow .headname{
    text-decoration: none;
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;

}
.sidebarsec .menulist  .activemenus button .iconrow .headname{

    color: var(--blacktext);
  
}
.whtclr{
    color: var(--whitetext);
}
.userc{
    width: 30px;
    height: 30px !important;
    object-fit: contain;
    aspect-ratio: 3/2;
}
@media screen and (max-width: 1199px) {
    .sidebarsec.mobilemenu {
        position: fixed;
        width: 270px;
        display: none;
        top: 0px;
        z-index: 999;
    }

    .sidebarsec.mobilemenu.mobilemenuopen {
        display: block;
    }
}